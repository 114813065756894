import React, {type ReactElement} from 'react';
import SourcePopUpContents from '../SourcePopUpContents';
import type Props from '../ArgumentTypes/PopUpProps';

/**
 * React container containing elements of video analytics component.
 *
 * @param props - The props of the component.
 * @returns React container containing elements of video analytics component.
 */
function SourcePopUpContainer(props: Props): ReactElement {
	return (
		<div>
			<SourcePopUpContents {...props}/>
		</div>
	);
}

export default SourcePopUpContainer;
