import type ApiGatewaySocket from './server';
import {type LiveStream} from './model/livestream';
import {type ViewRequest} from './model/viewRequest';
import {type Payload} from './model/messages';
import {type ScheduleRecordingRequest, type ScheduledRecording} from './model/recording';
import {type Visualization} from './model/visualization';
import {type GeoFence} from './model/geofence';
import {type LastWarning, type VideoChunk, type VideoStream} from './model/videostream';

export default class VideoService {
	socket: ApiGatewaySocket | undefined = undefined;

	/**
	 * Creates an instance of this class.
	 *
	 * @param socket - The APIGatewaySocket to connect to the back-end.
	 */
	constructor(socket: ApiGatewaySocket) {
		this.socket = socket;
	}

	/**
	 * Add a livestream to the capture loop.
	 *
	 * @param url - The url of the livestream.
	 * @param name - The name of the livestream.
	 * @param isVideo - A flag indicating whether the source is a video or a livestream.
	 * @param geofenceFlag - A flag indicating whether the source requires a geofence.
	 * @param techniques - The techniques to apply to the stream.
	 * @param techniqueCaptureIntervals - Map of techniques to capture intervals.
	 * @param cameraViewIndex - Index of frame to use as camera view.
	 * @param techniqueObjectsToDetect - Objects to detect for each technique.
	 */
	// eslint-disable-next-line max-params
	addSource(
		url: string,
		name: string,
		isVideo: boolean,
		geofenceFlag: boolean,
		techniques: string[],
		techniqueCaptureIntervals: Record<string, number> | undefined,
		cameraViewIndex: number | undefined,
		techniqueObjectsToDetect: Record<string, string[]> | undefined,
	): void {
		if (!this.socket) {
			throw Error('Failed to add a livestream because socket is undefined or null');
		}

		const livestream: LiveStream = {
			url,
			name,
			isVideo,
			geofenceFlag,
			techniques,
			cameraViewIndex,
			techniqueCaptureIntervals,
			techniqueObjectsToDetect,
		};
		const payload: Payload = {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			payload_type: 'ADD_LIVESTREAM',
			body: livestream,
		};

		this.socket.sendMessage(payload, 'VIDEO_INGESTION');
	}

	/**
	 *
	 * @param key - The key (path) of the file in the S3 bucket.
	 * @param name - The name of the video.
	 * @param geofenceFlag - A flag indicating whether the source requires a geofence.
	 * @param techniques - The techniques to be applied to the video.
	 * @param techniqueCaptureIntervals - The application i nterval of each technique.
	 * @param cameraViewIndex - The index of the camera view selected.
	 * @param techniqueObjectsToDetect - The objects to be detected by each technique.
	 */
	addVideoS3(
		key: string,
		name: string,
		geofenceFlag: boolean,
		techniques: string[],
		techniqueCaptureIntervals: Record<string, number> | undefined,
		cameraViewIndex: number | undefined,
		techniqueObjectsToDetect: Record<string, string[]> | undefined,
	): void {
		if (!this.socket) {
			throw Error('Failed to add a livestream because socket is undefined or null');
		}

		const videoS3: VideoStream = {
			file: key,
			name,
			geofenceFlag,
			techniques,
			cameraViewIndex,
			techniqueCaptureIntervals,
			techniqueObjectsToDetect,
		};

		const payload: Payload = {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			payload_type: 'ADD_VIDEO_S3',
			body: videoS3,
		};

		this.socket.sendMessage(payload, 'VIDEO_SERVICE');
	}

	/**
	 *
	 * @param file - The part of video file to be sent.
	 * @param name - The name of the video.
	 * @param geofenceFlag - A flag indicating whether the source requires a geofence.
	 * @param techniques - The techniques to be applied to the video.
	 * @param techniqueCaptureIntervals - The application i nterval of each technique.
	 * @param cameraViewIndex - The index of the camera view selected.
	 * @param techniqueObjectsToDetect - The objects to be detected by each technique.
	 */
	addVideoFile(
		file: string,
		name: string,
		geofenceFlag: boolean,
		techniques: string[],
		techniqueCaptureIntervals: Record<string, number> | undefined,
		cameraViewIndex: number | undefined,
		techniqueObjectsToDetect: Record<string, string[]> | undefined,
	): void {
		if (!this.socket) {
			throw Error('Failed to add a livestream because socket is undefined or null');
		}

		const videostream: VideoStream = {
			file,
			name,
			geofenceFlag,
			techniques,
			cameraViewIndex,
			techniqueCaptureIntervals,
			techniqueObjectsToDetect,
		};
		const payload: Payload = {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			payload_type: 'ADD_VIDEO_FILE',
			body: videostream,
		};

		this.socket.sendMessage(payload, 'VIDEO_INGESTION');
	}

	/**
	 * Send video chunks that belong to an existing video file.
	 * @param fileChunk - The file chunk to be analyzed.
	 * @param name - The name of the video with which the chunk should be associated.
	 * @param chunkNumber - The number of the chunk.
	 */
	sendVideoChunk(
		fileChunk: string,
		name: string,
		chunkNumber: number,
	): void {
		if (!this.socket) {
			throw Error('Failed to add a livestream because socket is undefined or null');
		}

		const videochunk: VideoChunk = {
			name,
			fileChunk,
			chunkNumber,
		};
		const payload: Payload = {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			payload_type: 'ADD_VIDEO_CHUNK',
			body: videochunk,
		};

		this.socket.sendMessage(payload, 'VIDEO_INGESTION');
	}

	/**
	 * Send warning that video is done sending.
	 * @param name - The name of the video.
	 */
	sendLastWarning(
		name: string,
	): void {
		if (!this.socket) {
			throw Error('Failed to add a livestream because socket is undefined or null');
		}

		const lastWarning: LastWarning = {
			name,
		};
		const payload: Payload = {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			payload_type: 'LAST_VIDEO_CHUNK',
			body: lastWarning,
		};

		this.socket.sendMessage(payload, 'VIDEO_INGESTION');
	}

	/**
	 * Schedule a recording. Sends a message with the recording details to the back-end.
	 *
	 * @param scheduledRecording - The recording to schedule.
	 */
	scheduleRecording(scheduledRecording: ScheduledRecording): void {
		if (!this.socket) {
			throw Error('Failed to schedule recording because socket is undefined or null');
		}

		const body: ScheduleRecordingRequest = {
			name: scheduledRecording.name,
			technique: scheduledRecording.technique,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			livestream_name: scheduledRecording.livestreamName,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			start_datetime: scheduledRecording.startDateTime + ':00.000',
			// eslint-disable-next-line @typescript-eslint/naming-convention
			end_datetime: scheduledRecording.endDateTime + ':00.000',
		};
		const payload: Payload = {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			payload_type: 'SCHEDULE_RECORDING',
			body,
		};

		this.socket.sendMessage(payload, 'VIDEO_INGESTION');
	}

	/**
	 * Adds a geo-fence to be associated with a stream.
	 *
	 * @param stream_name - The name of relevant stream.
	 * @param points - The coordinates of the geo-fence.
	 * @param length - The length of the geo-fence.
	 */
	addGeoFence(stream_name: string, points: GLfloat[][], length: number): void {
		if (!this.socket) {
			throw new Error('Failed to add geo-fence because socket is undefined or null');
		}

		const geoFence: GeoFence = {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			stream_name,
			points,
			distance: length,
		};

		const payload: Payload = {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			payload_type: 'ADD_GEOFENCE',
			body: geoFence,
		};

		this.socket.sendMessage(payload, 'VIDEO_INGESTION');
	}

	/**
	 * Selects the visual validation the user should be presented with.
	 *
	 * @param stream_name - The name of the stream to be visualized.
	 * @param technique - The technique to be visualized.
	 */
	selectVisualization(
		stream_name: string,
		technique: string,
	): void {
		if (!this.socket) {
			throw Error('Failed to select visualization because socket is undefined or null');
		}

		const visualization: Visualization = {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			stream_name,
			technique,
		};
		const payload: Payload = {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			payload_type: 'SET_VISUALIZATION',
			body: visualization,
		};

		this.socket.sendMessage(payload, 'VIDEO_INGESTION');
	}

	/**
	 * Deletes a livestream from the capture loop.
	 *
	 * @param stream_name - The name of relevant stream.
	 */
	deleteLiveStream(
		stream_name: string,
	): void {
		if (!this.socket) {
			throw Error('Failed to delete livestream because socket is undefined or null');
		}

		const payload: Payload = {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			payload_type: 'DELETE_LIVESTREAM',
			body: stream_name,
		};
		this.socket.sendMessage(payload, 'VIDEO_INGESTION');
	}

	/**
	 * Deletes a recording from the capture loop.
	 *
	 * @param recording_name - Name of recording to be deleted.
	 */
	deleteRecording(
		recording_name: string,
	): void {
		if (!this.socket) {
			throw Error('Failed to delete recording because socket is undefined or null');
		}

		const payload: Payload = {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			payload_type: 'DELETE_RECORDING',
			body: recording_name,
		};
		this.socket.sendMessage(payload, 'VIDEO_INGESTION');
	}

	/**
	 * Request frames for the back-end for camera view selection for a moving camera.
	 *
	 * @param url - The url of the livestream.
	 * @param name - The name of the livestream.
	 * @param techniques - The techniques to apply to the stream.
	 * @param movingCameraCaptureInterval - The interval to capture frames for the user to select view.
	 * @param movingCameraCaptureDuration - The duration for the frames to be captured for the user to select view.
	 * @param techniqueObjectsToDetect - The objects to detect for each technique.
	 */
	requestViewSelection(// eslint-disable-line max-params
		url: string,
		name: string,
		techniques: string[],
		movingCameraCaptureInterval: number,
		movingCameraCaptureDuration: number,
		techniqueObjectsToDetect: Record<string, string[]> | undefined,
	): void {
		if (!this.socket) {
			throw Error('Failed to request a view selection because socket is undefined or null');
		}

		const viewRequest: ViewRequest = {
			url,
			name,
			techniques,
			movingCameraCaptureInterval,
			movingCameraCaptureDuration,
			techniqueObjectsToDetect,
		};
		const payload: Payload = {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			payload_type: 'REQUEST_VIEW_SELECTION',
			body: viewRequest,
		};

		this.socket.sendMessage(payload, 'VIDEO_INGESTION');
	}

	/**
	 * Deletes all recordings.
	 *
	 */
	deleteAllRecordings(
	): void {
		if (!this.socket) {
			throw Error('Failed to delete all livestreams because socket is undefined or null');
		}

		const payload: Payload = {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			payload_type: 'DELETE_ALL_RECORDINGS',
			body: [],
		};

		this.socket.sendMessage(payload, 'VIDEO_INGESTION');
	}
}
