import styles from '../../resources/styles/ComponentSpecific/PopUp.module.css';
import React, {type ReactElement} from 'react';
import type LabeledCheckboxProps from '../ArgumentTypes/UtilComponentsArgumentTypes/LabeledCheckboxProps';

/**
 * This component returns an interactive checkbox with text aligned on its right.
 *
 * @param props - The input props require to fill in this component.
 * @returns - ReactElement of checkbox and corresponding label.
 */
function LabeledCheckbox(props: LabeledCheckboxProps): ReactElement {
	return (
		<label className={styles.LabelCheckbox}>
			<input
				type='checkbox'
				className={styles.Input}
				aria-label={props.checkboxAriaLabel}
				checked={props.isCheckboxChecked}
				id='sourceCheckbox'
				onChange={props.onCheckboxSelectFunction}
			/>
			<span className={styles.SpanCheckbox}>{props.labelText}</span>
		</label>
	);
}

export default LabeledCheckbox;
