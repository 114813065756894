import type DeleteAllReplaysPopUpProps from '../ArgumentTypes/DeleteAllReplaysProps';
import DeleteAllReplaysPopUpContents from '../DeleteAllReplaysPopUpContents';
import React, {type ReactElement} from 'react';

/**
 * Returns a container for the delete all replays pop up component.
 *
 * @param props - The input parameters for the container.
 * @returns - React element rendering the delete all replays pop up component.
 */
export default function DeleteAllReplaysPopUpContainer(props: DeleteAllReplaysPopUpProps): ReactElement {
	return (
		<div>
			<DeleteAllReplaysPopUpContents {...props}/>
		</div>
	);
}
