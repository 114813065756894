import React, {
	useState,
	useEffect,
	useContext,
	createContext,
	type ReactElement,
} from 'react';
import ApiGatewaySocket from './server';
import {setError} from '../App';
type WebSocketContextType = ApiGatewaySocket | undefined;

const WebSocketContext = createContext<WebSocketContextType>(undefined);

type WebSocketProviderProps = Readonly<{
	url: string;
	children: React.ReactNode;
}>;

/**
 * Provides the entire application with a connection to the backend.
 * Wrap around App and call useWebSocket() to get access to the server.
 *
 * @param param0 - Contains url to server and child components with access to the server.
 * @param param0.url - Url for websocket connection.
 * @param param0.children - Children of this component.
 * @returns Component which contains a websocket connection in its context.
 */
function WebSocketProvider({
	url,
	children,
}: WebSocketProviderProps): ReactElement {
	const [ws, setWs] = useState<WebSocketContextType>(undefined);
	const [errorThrown, setErrorThrown] = useState(false);

	console.log('WebSocketProvider: url: ', url);

	useEffect(() => {
		try {
			const socket = new ApiGatewaySocket(new WebSocket(url));
			setWs(socket);

			return () => {
				socket.socket?.close();
			};
		} catch (error) {
			console.error('Failed to connect to WebSocket server:', error);
			setErrorThrown(true);
		}
	}, [url]);

	if (errorThrown) {
		setError('Failed to connect to server', 'Please try again');
	}

	return (
		<WebSocketContext.Provider value={ws}>
			{children}
		</WebSocketContext.Provider>
	);
}

/**
 * Get instance of websocket connection to backend. Recalling this function in the same provider should give the same connection.
 *
 * @returns The AGWSocket instance.
 */
function useWebSocket(): WebSocketContextType {
	const ws = useContext(WebSocketContext);
	if (!ws) {
		throw new Error('useWebSocket must be used within a WebSocketProvider');
	}

	return ws;
}

export {WebSocketProvider, useWebSocket};
