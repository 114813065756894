import React, {type ReactElement} from 'react';
import type Props from './ArgumentTypes/ObjectsDetectProps';
import styles from '../resources/styles/ComponentSpecific/Checklist.module.css';

/**
 * React component containing elements of the checklist.
 *
 * @param props - The props of the component.
 * @returns React component containing elements of the checklist for objects to detect.
 */
function ChecklistTableContents(props: Props): ReactElement {
	return (
		<div className={styles.Checklist}>
			<div>{props.title}</div>
			<div className={styles.ListContainer}>

				{props.data.map(item => (
					<div key={item}>
						<input
							type='checkbox'
							onChange={e => {
								props.onSelect(item, e.target.checked);
							}}
						/>
						<span>{item}</span>
					</div>
				))}
			</div>
		</div>
	);
}

export default ChecklistTableContents;
