import React, {type ReactElement} from 'react';
import SelectedReplayIndicatorContents from '../SelectedReplayIndicatorContents';
import {type SelectedReplayIndicatorProps} from '../ArgumentTypes/SelectedReplayIndicatorProps';
import styles from '../../resources/styles/ComponentSpecific/VideoSources.module.css';

/**
 * React container containing elements of the component indicating the selected replay/recording.
 *
 * @param props - The props of the component.
 * @returns React container containing elements of the replay/recording source indicator component.
 */
function SelectedReplayIndicatorContainer(props: SelectedReplayIndicatorProps): ReactElement {
	return (
		<div className={styles.SourceIndContainer}>
			<SelectedReplayIndicatorContents {...props}/>
		</div>
	);
}

export default SelectedReplayIndicatorContainer;
