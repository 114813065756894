import React, {type ReactElement} from 'react';
import {type DataResultsProps} from '../ArgumentTypes/DataResultsProps';
import DataResults from '../DataResultsContents';
import styles from '../../resources/styles/ComponentSpecific/DataResults.module.css';

/**
 * A container for the contents of the Data Results Contents.
 * @param props - The inout parameters needed for the Data Results.
 * @returns The container for the Data Reuslts contents.
 */
export default function DataResultsContainer(props: DataResultsProps): ReactElement {
	return (
		<div className={styles.WrapperContainer}>
			<DataResults {...props}/>
		</div>
	);
}
