import React, {type ReactElement} from 'react';
import VideoSrcConts from '../VideoSourcesContents';
import styles from '../../resources/styles/ComponentSpecific/VideoSources.module.css';
import {type SetSourcesProps} from '../ArgumentTypes/SetSourcesProps';

/**
 * React container containing elements of the video sources container.
 *
 * @param props - The props of the component.
 * @returns React container containing elements of the video sources container.
 */
function VideoSourcesContainer(props: SetSourcesProps): ReactElement {
	return (
		<div className={styles.ContainerWrapperPlacing}>
			<VideoSrcConts {...props}/>
		</div>
	);
}

export default VideoSourcesContainer;
