import React, {type ReactElement} from 'react';
import DeletePopUpContents from '../DeletePopUpContents';
import type Props from '../ArgumentTypes/DeletePopUpProps';

/**
 * React container containing elements of the delete stream pop up component.
 *
 * @param props - The props of the component, the data passed from the parent component.
 * @returns React container containing elements of delete stream pop up component.
 */
function DeletePopUpContainer(props: Props): ReactElement {
	return (
		<div>
			<DeletePopUpContents {...props}/>
		</div>
	);
}

export default DeletePopUpContainer;
