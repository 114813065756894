import React, {type ReactElement} from 'react';
import stylesCommon from '../resources/styles/ComponentSpecific/MainBody.module.css';
import stylesSources from '../resources/styles/ComponentSpecific/VideoSources.module.css';
import {type SourceIndicatorProp} from './ArgumentTypes/SourceIndicatorProp';

/**
 * React component containing elements of component indicating selected video source.
 *
 * @param props - The props of the component.
 * @returns React component containing elements of source indicator component.
 */
function SourceIndicatorContents(props: SourceIndicatorProp): ReactElement {
	return (
		<div id='source_indicator' className={stylesSources.SourceInd}>
			<div className={stylesCommon.Indicators}>Current source:</div>
			<div id='current_source' className={stylesCommon.Text}>{props.sourceName}</div>
		</div>
	);
}

export default SourceIndicatorContents;
