import React, {type ReactElement} from 'react';
import ChecklistContents from '../ChecklistContents';
import type Props from '../ArgumentTypes/ChecklistProps';
import styles from '../../resources/styles/ComponentSpecific/Checklist.module.css';

/**
 * React container containing elements of a scrollable checklist.
 *
 * @param props - The props of the component.
 * @returns React container containing elements of a checklist.
 */
function ChecklistContainer(props: Props): ReactElement {
	return (
		<div className={styles.Container}>
			<ChecklistContents {...props}/>
		</div>
	);
}

export default ChecklistContainer;
