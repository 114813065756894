import React, {type ReactElement} from 'react';
import VisualizationContents from '../VisualizationContents';
import styles from '../../resources/styles/ComponentSpecific/MainBody.module.css';
import {type VisualizationProps} from '../ArgumentTypes/VisualizationProps';

/**
 * React container containing elements of the visualization area container.
 *
 * @param props - The input parameters for the Visualization container.
 * @returns React container containing elements of the visualization area container.
 */
function VisualizationContainer(props: VisualizationProps): ReactElement {
	return (
		<div
			className={styles.Visualization}
			id={props.styleId}
		>
			<VisualizationContents {...props}/>
		</div>
	);
}

export default VisualizationContainer;
