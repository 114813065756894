import React, {type ReactElement} from 'react';
import type ChecklistProps from './ArgumentTypes/ChecklistProps';
import styles from '../resources/styles/ComponentSpecific/Checklist.module.css';

/**
 * React component containing elements of the checklist.
 *
 * @param props - The props of the component.
 * @returns React component containing elements of the checklist.
 */
function ChecklistTableContents(props: ChecklistProps): ReactElement {
	return (
		<div className={styles.Checklist}>
			<div className='title'>{props.title}</div>
			<div className={styles.ListContainer}>
				{props.data.map(item => (
					<div key={item.name} className={styles.listItem}>
						<input
							value={item.name}
							type='checkbox'
							onChange={e => {
								props.onSelect(item, e.target.checked);
							}}
						/>
						<span>{item.name}</span>
						{props.renderExtra?.(item)}
					</div>
				))}
			</div>
		</div>
	);
}

export default ChecklistTableContents;
