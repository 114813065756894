import React, {type ReactElement, useEffect, useState} from 'react';
import {type ApexOptions} from 'apexcharts';
import Chart from 'react-apexcharts';
import {type DayCurveProps} from './ArgumentTypes/DayCurveProps';

/**
 * Creates the contents of the window with the day curve.
 * @param props - The specifics of the contents.
 * @returns - Element with day-curve.
 */
export default function DayCurve(props: DayCurveProps): ReactElement {
	const [metadata, setmetadata] = useState<Array<Record<string, number>>>([]);
	const [series, setSeries] = useState<Array<{name: string; data: number[][]}>>([]);

	/**
	 * Fetches metadata for one replay data point.
	 * @param url - The url where the data can be found.
	 * @returns - The record of key and value.
	 */
	async function fetchMetadata(url: string): Promise<Record<string, number>> {
		const response = await fetch(url);
		const data = (await response.json()) as Record<string, number>;
		console.log(data);
		return data;
	}

	useEffect(() => {
		/**
		 * Gets metadata for all replay data points.
		 */
		async function fetchAll(): Promise<void> {
			let all: Array<Record<string, number>> = [];
			const fetching = props.data.map(async value => {
				const newval = await fetchMetadata(value[1]);
				console.log(newval);
				all = [...all, newval];
			});
			await Promise.all(fetching);

			setmetadata(all);
		}

		if (props.data.length > 0) {
			fetchAll().catch(error => {
				console.error('Failed to fetch metadata:', error);
			});
		}
	}, [props]);

	useEffect(() => {
		if (props.data.length > 0 && metadata.length > 0) {
			const updatedSeries = [
				{
					name: props.title,
					data: props.data.map((dataPoint, i) => [
						new Date(dataPoint[2]).getTime(),
						metadata[i] && 'people' in metadata[i] ? metadata[i].people : 0,
					]),
				},
			];
			setSeries(updatedSeries);
		}
	}, [metadata]);

	console.log(metadata);

	const options: ApexOptions = {
		tooltip: {x: {format: 'dd MMM HH:mm:ss'}},
		stroke: {width: 2},
		title: {text: props.title},
		colors: ['#ff9900'],
		chart: {
			id: 'intensity',
			type: 'line',
			height: '100%',
			width: '100%',
			events: {
				mouseMove(e, chart?, options?) {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					const {dataPointIndex}: {dataPointIndex: number} = options;
					const maxImages: number = metadata.length;
					props.set(Math.max(Math.min(maxImages - 1, dataPointIndex), 0));
				},
			},
		},
		xaxis: {
			type: 'datetime',
			labels: {
				// eslint-disable-next-line @typescript-eslint/naming-convention
				datetimeUTC: false,
				format: 'dd MMM HH:mm:ss',
			},
		},
		yaxis: [
			{
				title: {text: props.yAxisLabel},
				decimalsInFloat: 0,
			},
		],
	};
	return (
		<Chart options={options} series={series}/>
	);
}

;
