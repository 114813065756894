import React, {type ReactElement, useState} from 'react';
import stylesCommon from '../resources/styles/ComponentSpecific/MainBody.module.css';
import stylesAnalytics from '../resources/styles/ComponentSpecific/VideoAnalytics.module.css';
import buttons from '../resources/styles/ElementSpecific/Buttons.module.css';
import containers from '../resources/styles/ElementSpecific/Container.module.css';
import {type VideoAnalyticsProps} from './ArgumentTypes/VideoAnalyticsProps';
import TechniquesGrid from './TechniquesGridContents';
import ReactModal from 'react-modal';
import GeoFencePopUpContainer from './containers/GeoFencePopUpContainser';
import SelectCameraViewPopUpContainer from './containers/SelectCameraViewPopUpContainer';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faQuestionCircle} from '@fortawesome/free-regular-svg-icons';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

/**
 * React component containing elements of video analytics components.
 *
 * @param props - The props of the component.
 * @returns React component containing elements of video analytics.
 */
function VideoAnalyticsContents(props: VideoAnalyticsProps): ReactElement {
	const [geoFenceOpen, setGeoFenceOpen] = useState<boolean>(false);
	const [selectViewOpen, setSelectViewOpen] = useState<boolean>(false);

	const selectedStreamRequiresViewSelection = props.streamsRequiringViewSelection.some(
		([, name]) => name === props.selectedStream,
	);

	return (
		<div className={stylesAnalytics.ContainingDiv}>
			<h2 className={stylesCommon.SectionTitle}>
				Video Analytics
				<Tippy content='After selecting a stream, you can use this section to select a technique to visualize. Once a technique has been selected you can view the results in the Live Analytics Results section below'>
					<FontAwesomeIcon icon={faQuestionCircle} style={{marginLeft: '10px'}}/>
				</Tippy>
			</h2>
			<div id='VideoAnalytics' className={containers.Container}>
				{!selectedStreamRequiresViewSelection && (props.sourceName === 'None') && (
					<div className={stylesCommon.Indicators}>
						No video source selected
					</div>
				)}
				{!selectedStreamRequiresViewSelection && props.selected && props.geoFenceNeeded && (
					<div className={stylesCommon.Indicators}>
						Please create a Geo-Fence!
					</div>
				)}
				{!selectedStreamRequiresViewSelection && props.analysisTechniques.length > 0 && !props.geoFenceNeeded && (
					<div className={stylesCommon.Indicators}>
						Click on a technique to view its results!
					</div>
				)}
				{selectedStreamRequiresViewSelection && (
					<>
						<div className={stylesCommon.Indicators}>
							Please select a view to begin processing this stream!
						</div>
						<button
							className={buttons.Button}
							id='SelectView'
							type='button'
							onClick={() => {
								setSelectViewOpen(true);
							}}
						>
							Select View
						</button>
						<ReactModal
							className={stylesCommon.Modal}
							isOpen={selectViewOpen}
							onRequestClose={() => {
								setSelectViewOpen(false);
							}}
						>
							<SelectCameraViewPopUpContainer
								sourceName={props.sourceName}
								streamsRequiringViewSelection={props.streamsRequiringViewSelection}
								getAndRemoveStreamRequiringViewByName={props.getAndRemoveStreamRequiringViewByName}
								setAnalysisTechniques={props.setAnalysisTechniques}
								onCloseProp={() => {
									setSelectViewOpen(false);
								}}
							/>
						</ReactModal>
					</>
				)}
				{!selectedStreamRequiresViewSelection && props.analysisTechniques.length > 0 && (
					<>
						<TechniquesGrid
							analysisTechniques={props.analysisTechniques}
							selected={props.selected}
							setSelected={props.setSelected}
							selectedStream={props.selectedStream}
						/>
						<button
							className={buttons.Button}
							id='AddGeoFence'
							type='button'
							onClick={() => {
								setGeoFenceOpen(true);
							}}
						>
							Add Geo-Fence
							<Tippy content='Add a geo-fence to confine the application of the techniques to a certain area of the video-stream. Also add a distance measure to your geo-fence to make speed-estimation more accurate.'>
								<FontAwesomeIcon icon={faQuestionCircle} style={{marginLeft: '3px'}}/>
							</Tippy>
						</button>
						<ReactModal
							className={stylesCommon.Modal}
							isOpen={geoFenceOpen}
							onRequestClose={() => {
								setGeoFenceOpen(false);
							}}
						>
							<GeoFencePopUpContainer
								setGeoFenceFlag={props.setGeoFenceNeeded}
								sourceName={props.selectedStream}
								onClose={() => {
									setGeoFenceOpen(false);
								}}
							/>
						</ReactModal>
					</>
				)}
			</div>
		</div>
	);
}

export default VideoAnalyticsContents;
