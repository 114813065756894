import React, {type ReactElement} from 'react';
import {useState} from 'react';
import styles from '../resources/styles/ComponentSpecific/PopUp.module.css';
import buttons from '../resources/styles/ElementSpecific/Buttons.module.css';
import type Props from './ArgumentTypes/AddReplayPopUpProps';
import type ApiGatewaySocket from '../api/server';
import {useWebSocket} from '../api/AGWContext';
import VideoService from '../api/VideoService';
import {streamTechniqueDictionary} from './MainBodyContents';
import {techniqueDictionary} from '../Types/Technique';
import {type StreamTechniqueDictionaryType} from '../Types/StreamTechniqueDictionary';
import {type ScheduledRecording} from '../api/model/recording';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import {type DateTimeValidationError} from '@mui/x-date-pickers';
import {StyledEngineProvider, ThemeProvider, createTheme} from '@mui/material/styles';
import moment from 'moment';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {type PickerChangeHandlerContext} from '@mui/x-date-pickers/internals/hooks/usePicker/usePickerValue.types.js';

const theme = createTheme({
	palette: {
		primary: {
			main: '#F2B457', // The Scenwise yellow
		},
		action: {
			active: '#F2B457', // The Scenwise yellow
			selected: '#F2B457', // The Scenwise yellow
			focus: '#F2B457', // The Scenwise yellow
		},
	},
});

/**
 * Component which contains component of the add replay popup.
 *
 * @param props - The props of the component.
 * @returns The jsx of the component.
 */
function AddReplayPopUpContents(props: Props): ReactElement {
	const ws: ApiGatewaySocket | undefined = useWebSocket();
	const streamTechniqueDictionaryValue = streamTechniqueDictionary.getValue() as StreamTechniqueDictionaryType;

	/**
	 * Submission of add recording form.
	 *
	 * @param event - The submit event.
	 * @param onClose - Function which determines closing behavior of pop-up.
	 */
	const handleSubmit = (
		event: React.MouseEvent<HTMLButtonElement>,
		onClose: () => void,
	): void => {
		event.preventDefault(); // Prevents refresh on submit

		if (ws) {
			if (!recordingName) {
				setRecordingNameErrorMessage('You must specify a name for the recording!');
				return;
			}

			setRecordingNameErrorMessage('');

			if (!livestreamName) {
				setLivestreamNameErrorMessage('You must specify a livestream!');
				return;
			}

			setLivestreamNameErrorMessage('');

			if (!technique) {
				setTechniqueErrorMessage('You must specify a technique!');
				return;
			}

			setTechniqueErrorMessage('');

			if (!startDateTime) {
				setStartDateTime('You must specify a starting time!');
				return;
			}

			setStartDateTime('');

			if (!endDateTime) {
				setEndDateTimeErrorMessage('You must specify an ending data!');
				return;
			}

			setEndDateTimeErrorMessage('');

			const videoService = new VideoService(ws);
			const name = recordingName;
			const scheduledRecording: ScheduledRecording = {
				name,
				livestreamName,
				technique,
				startDateTime,
				endDateTime,
			};

			videoService.scheduleRecording(
				scheduledRecording,
			);

			onClose();
		}
	};

	const [recordingName, setRecordingName] = useState<string | undefined>(undefined);
	const [livestreamName, setLivestreamName] = useState<string | undefined>(undefined);
	const [technique, setTechnique] = useState<string | undefined>(undefined);
	const [startDateTime, setStartDateTime] = useState<string | undefined>(undefined);
	const [endDateTime, setEndDateTime] = useState<string | undefined>(undefined);

	const [recordingNameErrorMessage, setRecordingNameErrorMessage] = useState<string>('');
	const [livestreamNameErrorMessage, setLivestreamNameErrorMessage] = useState<string>('');
	const [techniqueErrorMessage, setTechniqueErrorMessage] = useState<string>('');
	const [startDateTimeErrorMessage, setStartDateTimeErrorMessage] = useState<string>('');
	const [endDateTimeErrorMessage, setEndDateTimeErrorMessage] = useState<string>('');

	/**
	 * Handler for changing the recording name in the add recording form.
	 *
	 * @param e - Change event.
	 */
	const handleRecordingNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		const inputValue = e.target.value;

		if (inputValue.includes(' ')) {
			setRecordingNameErrorMessage('The name cannot contain spaces!');
		} else if (e.target.validity.valid) {
			setRecordingNameErrorMessage('');
			setRecordingName(e.target.value);
		} else {
			setRecordingNameErrorMessage('This name is invalid!');
		}
	};

	/**
	 * Handler for changing the livestream name in the add recording form.
	 *
	 * @param e - Change event.
	 */
	const handleLivestreamNameChange = (e: React.ChangeEvent<HTMLElement>): void => {
		const selectElement = e.target as HTMLSelectElement; // Type assertion to HTMLSelectElement

		if (selectElement.value) {
			setLivestreamNameErrorMessage('');
			setLivestreamName(selectElement.value);
		} else {
			setLivestreamNameErrorMessage('This name is invalid!');
		}
	};

	/**
	 * Handler for changing the technique in the add recording form.
	 *
	 * @param e - Change event.
	 */
	const handleTechniqueChange = (e: React.ChangeEvent<HTMLElement>): void => {
		const selectElement = e.target as HTMLSelectElement;

		if (selectElement.value) {
			setTechniqueErrorMessage('');
			setTechnique(selectElement.value);
		} else {
			setTechniqueErrorMessage('This technique is invalid!');
		}
	};

	/**
	 * Handler for changing the start date time in the add recording form.
	 *
	 * @param value - Change event.
	 * @param context - Chnage Context.
	 */
	// eslint-disable-next-line @typescript-eslint/ban-types
	const handleStartDateTimeChange = (value: Date | null, context: PickerChangeHandlerContext<DateTimeValidationError>): void => {
		if (value && !context.validationError) {
			setStartDateTimeErrorMessage('');
			setStartDateTime((moment(value)).format('YYYY-MM-DD HH:mm'));
		} else {
			setStartDateTimeErrorMessage('This start time is invalid!');
		}
	};

	/**
	 * Handler for changing the end date time in the add recording form.
	 *
	 * @param value - Change event.
	 * @param context - Chnage Context.
	 */
	// eslint-disable-next-line @typescript-eslint/ban-types
	const handleEndDateTimeChange = (value: Date | null, context: PickerChangeHandlerContext<DateTimeValidationError>): void => {
		if (value && !context.validationError) {
			setEndDateTimeErrorMessage('');
			setEndDateTime((moment(value)).format('YYYY-MM-DD HH:mm'));
		} else {
			setEndDateTimeErrorMessage('This start time is invalid!');
		}
	};

	React.useEffect(() => () => {
		setRecordingName(undefined);
		setLivestreamName(undefined);
		setTechnique(undefined);
		setStartDateTime(undefined);
		setEndDateTime(undefined);
	}, [props.onCloseProp]);

	return (
		<form className={styles.Window}>
			<h1 className={styles.Title}>Add Recording</h1>
			<p className={styles.Text}>Recording Name:</p>
			<input
				required
				type='text'
				className={styles.Input}
				aria-label='Recording Name:'
				onChange={handleRecordingNameChange}
			/>
			<p className={styles.Error}>{recordingNameErrorMessage}</p>
			<p className={styles.InputHint}>Make sure to use a new name and not include spaces.</p>
			<p className={styles.Text}>Stream Name:</p>
			<select
				required
				className={styles.Input}
				aria-label='Stream Name:'
				value={livestreamName ?? ''}
				onChange={handleLivestreamNameChange}
			>
				<option disabled value=''>Select Stream Name</option>
				{Object.keys(streamTechniqueDictionaryValue).map(key => (
					<option key={key} value={key}>
						{key}
					</option>
				))}
			</select>
			<p className={styles.Error}>{livestreamNameErrorMessage}</p>
			<p className={styles.Text}>Technique:</p>
			<select
				required
				className={styles.Input}
				aria-label='Technique:'
				disabled={!livestreamName}
				value={technique ?? ''}
				onChange={handleTechniqueChange}
			>
				<option disabled value=''>Select Technique</option>
				{!livestreamName && (
					<option disabled value=''>
						You must add a stream before specifying technique
					</option>
				)}
				{livestreamName && streamTechniqueDictionaryValue[livestreamName].map(technique_id => (
					<option key={technique_id} value={technique_id}>
						{techniqueDictionary[technique_id].name}
					</option>
				))}
			</select>

			<p className={styles.Error}>{techniqueErrorMessage}</p>
			<p className={styles.Text}>Start time:</p>
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<StyledEngineProvider injectFirst>
					<ThemeProvider theme={theme}>
						<DateTimePicker
							aria-label='Start Time :'
							ampm={false}
							views={['day', 'month', 'year', 'hours', 'minutes']}
							className={styles.Input}
							format='DD/MM/YYYY HH:mm'
							slotProps={{
								textField: {
									size: 'small',
								},
							}}
							sx={{
								'& .MuiOutlinedInput-notchedOutline': {
									border: 'none',
								},
							}}
							onChange={handleStartDateTimeChange}
						/>
					</ThemeProvider>
				</StyledEngineProvider>
			</LocalizationProvider>
			<p className={styles.Error}>{startDateTimeErrorMessage}</p>
			<p className={styles.Text}>End Time:</p>
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<StyledEngineProvider injectFirst>
					<ThemeProvider theme={theme}>
						<DateTimePicker
							aria-label='End Time :'
							ampm={false}
							views={['day', 'month', 'year', 'hours', 'minutes']}
							className={styles.Input}
							format='DD/MM/YYYY HH:mm'
							slotProps={{
								textField: {
									size: 'small',
								},
							}}
							sx={{
								'& .MuiOutlinedInput-notchedOutline': {
									border: 'none',
								},
							}}
							onChange={handleEndDateTimeChange}
						/>
					</ThemeProvider>
				</StyledEngineProvider>
			</LocalizationProvider>
			<p className={styles.Error}>{endDateTimeErrorMessage}</p>
			<div className={styles.ButtonsDiv}>
				<button
					type='button'
					id='Close'
					className={buttons.Button}
					onClick={props.onCloseProp}
				>
					Close
				</button>
				<button
					type='submit'
					className={buttons.Button}
					onClick={e => {
						handleSubmit(e, props.onCloseProp);
					}}
				>
					Add
				</button>
			</div>
		</form>
	);
}

export default AddReplayPopUpContents;
