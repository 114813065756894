import type ViewReplayPopUpProps from '../ArgumentTypes/ViewReplayPopUpProps';
import ViewReplayPopUpContents from '../ViewReplayPopUpContents';
import React, {type ReactElement} from 'react';

/**
 * Returns a container for the view replay pop up component.
 *
 * @param props - The input parameters for the container.
 * @returns - React element rendering the view replay pop up component.
 */
export default function ViewReplayPopUpContainer(props: ViewReplayPopUpProps): ReactElement {
	return (
		<div>
			<ViewReplayPopUpContents {...props}/>
		</div>
	);
}
