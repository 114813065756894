import type ApiGatewaySocket from './server';
import {type Payload} from './model/messages';
import {type RecordingRequest} from './model/recording';

export default class VisualizationService {
	socket: ApiGatewaySocket | undefined = undefined;

	/**
	 * Creates an instance of this class.
	 *
	 * @param socket - The APIGatewaySocket to connect to the back-end.
	 */
	constructor(socket: ApiGatewaySocket) {
		this.socket = socket;
	}

	/**
	 * Requests a recording.
	 *
	 * @param recording_name - The name of the recording.
	 */
	requestRecording(
		recording_name: string,
	): void {
		if (!this.socket) {
			throw Error('Failed to add a livestream because socket is undefined or null');
		}

		const recordingRequest: RecordingRequest = {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			recording_name,
		};
		const payload: Payload = {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			payload_type: 'REQUEST_RECORDING',
			body: recordingRequest,
		};

		this.socket.sendMessage(payload, 'VISUALIZATION');
	}

	/**
	 * Deletes all recordings.
	 *
	 */
	deleteAllRecordings(
	): void {
		if (!this.socket) {
			throw Error('Failed to delete all livestreams because socket is undefined or null');
		}

		const payload: Payload = {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			payload_type: 'DELETE_RECORDINGS',
			body: '',
		};

		this.socket.sendMessage(payload, 'VISUALIZATION');
	}

	/**
	 * Deletes a recording from the capture loop.
	 *
	 * @param recording_name - Name of recording to be deleted.
	 */
	deleteRecording(
		recording_name: string,
	): void {
		if (!this.socket) {
			throw Error('Failed to delete recording because socket is undefined or null');
		}

		const payload: Payload = {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			payload_type: 'DELETE_RECORDINGS',
			body: recording_name,
		};
		this.socket.sendMessage(payload, 'VISUALIZATION');
	}
}
