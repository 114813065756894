/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {setErrorAlert} from '../components/MainBodyContents';
import {type Message} from './model/messages';

/**
 * Handle a message from the computer vision servise.
 *
 * @param message - The message received.
 */
export default function handleComputerVisionMessage(message: Message): void {
	const {body} = message.payload;
	switch (message.payload.payload_type) {
		case 'SEND_ANALYSIS_ERROR': {
			setErrorAlert(body);
			break;
		}

		default: {
			throw Error('Unexpected payload type');
		}
	}
}
