import React, {type ReactElement} from 'react';
import stylesCommon from '../resources/styles/ComponentSpecific/MainBody.module.css';
import stylesReplay from '../resources/styles/ComponentSpecific/Replay.module.css';
import {type SelectedReplayIndicatorProps} from './ArgumentTypes/SelectedReplayIndicatorProps';

/**
 * React component containing elements of component indicating selected replay/recording.
 *
 * @param props - The props of the component.
 * @returns React component containing elements of replay/recording indicator component.
 */
export default function SelectedReplayIndicatorContents(props: SelectedReplayIndicatorProps): ReactElement {
	return (
		<div id='source_indicator'>
			<div className={stylesCommon.Indicators}>Current Selected Replay/Recording:</div>
			<div id='current_source' className={stylesCommon.Text}>
				{props.selectedRecording ? props.selectedRecording.name : 'No selected recording'}
				<div className={stylesReplay.SmallText}>
					Livestream: {props.selectedRecording ? props.selectedRecording.livestreamName : '-'}<br/>
					Technique: {props.selectedRecording ? props.selectedRecording.technique : '-'}<br/>
					Start Time: {props.selectedRecording ? props.selectedRecording.startDateTime : '-'}<br/>
					End Time: {props.selectedRecording ? props.selectedRecording.endDateTime : '-'}
				</div>
			</div>
		</div>
	);
}
