/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react/jsx-closing-tag-location */
import React, {useState, type ReactElement} from 'react';
import stylesCommon from '../resources/styles/ComponentSpecific/MainBody.module.css';
import containers from '../resources/styles/ElementSpecific/Container.module.css';
import {createState} from 'state-pool';
import {type AnalysisResultsProps} from './ArgumentTypes/AnalysisResultsProps';
import {type Metadata} from '../Types/MetadataTypes';
import DataResultsContainer from './containers/DataResultsContainer';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faQuestionCircle} from '@fortawesome/free-regular-svg-icons';
import Tippy from '@tippyjs/react';

const width = createState<number>(1);
const height = createState<number>(1);
const imageSourceOriginal = createState<string>('');
const imageSourceValidation = createState<string>('');

const analysisResult = createState<Metadata | undefined>(undefined);

/**
 * Set the global state of the width variable to the input value.
 *
 * @param w - The inputted width of the image.
 */
export function setWidth(w: number): void {
	width.setValue(w);
}

/**
 * Set the global state of the height variable to the input value.
 *
 * @param h - The inputed height of the image.
 */
export function setHeight(h: number): void {
	height.setValue(h);
}

/**
 * Set the global state of the imageData variable to the input value.
 *
 * @param im - The imageData of the image to be rendered.
 */
export function setOriginalImage(im: string): void {
	imageSourceOriginal.setValue(im);
}

/**
 * Set the global state of the imageData variable to the input value.
 *
 * @param im - The imageData of the image to be rendered.
 */
export function setValidationImage(im: string): void {
	imageSourceValidation.setValue(im);
}

/**
 * Set the global state of the analysisResult variable to the input value.
 *
 * @param metadata - The metadata received from the backend which contains the result of the analysis.
 */
export function setAnalysisResult(metadata: any): void {
	if (metadata satisfies Metadata) {
		analysisResult.setValue(metadata);
	}
}

/**
 * React component containing elements of the video analytics results component.
 *
 * @param props - The input parameters passed to the component.
 * @returns React component containing elements of the video analytics results.
 */
function AnalyticsResultsContents(props: AnalysisResultsProps): ReactElement {
	const [imSourceOriginal] = imageSourceOriginal.useState();
	const [imSourceValidation] = imageSourceValidation.useState();
	const [result] = analysisResult.useState();
	const [loadedOriginal, setLoadedOriginal] = useState(true);
	const [loadedVisual, setLoadedVisual] = useState(true);

	return (
		<div>
			<h2 className={stylesCommon.SectionTitle}>Live Analysis Results
				<Tippy content='Once you have selected a stream and a technique in the above sections, you can visualize the live results here. The results include live data (counts, speeds etc) together with the video frame and a visual validation of the frame (bounding boxes around cars, traces behind moving vehicles etc).'>
					<FontAwesomeIcon icon={faQuestionCircle} style={{marginLeft: '10px'}}/>
				</Tippy>
			</h2>
			<div id='AnalysisResults' className={containers.Container}>
				{!props.selected && <p className={stylesCommon.Indicators}>
					No video source selected
				</p>}
				{props.selected && !result && <p className={stylesCommon.Indicators}>
					Waiting for results...
				</p>}
				{props.selected && result && <div>
					<p className={stylesCommon.Indicators}>Results</p>
					<DataResultsContainer metadata={result}/>
				</div>}
				<img
					id='OriginalViz'
					src={`data:image/png;base64,${imSourceOriginal}`}
					onError={event => {
						const e = event.target as HTMLImageElement;
						e.style.display = 'none';
						setLoadedOriginal(false);
						return undefined;
					}}
					onLoad={event => {
						const e = event.target as HTMLImageElement;
						e.style.display = 'block';
						setLoadedOriginal(true);
					}}
				/>
				{!loadedOriginal && (
					<div
						className={stylesCommon.ring}
					>Loading <span className={stylesCommon.LoadingSpan}/>
					</div>)}
				<img
					id='ValidationViz'
					src={`data:image/png;base64,${imSourceValidation}`}
					onError={event => {
						const e = event.target as HTMLImageElement;
						e.style.display = 'none';
						setLoadedVisual(false);
					}}
					onLoad={event => {
						const e = event.target as HTMLImageElement;
						e.style.display = 'block';
						setLoadedVisual(true);
					}}
				/>
				{!loadedVisual && (
					<div
						className={stylesCommon.ring}
					>Loading <span className={stylesCommon.LoadingSpan}/>
					</div>)}
			</div>
		</div>
	);
}

export default AnalyticsResultsContents;
