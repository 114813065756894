import React, {type ReactElement} from 'react';
import HeaderContents from '../HeaderContents';
import styles from '../../resources/styles/ComponentSpecific/Containers.module.css';

/**
 * React container containing elements of header.
 *
 * @returns React container containing elements of header.
 */
function HeaderContainer(): ReactElement {
	return (
		<div className={styles.Header}>
			<HeaderContents/>
		</div>
	);
}

export default HeaderContainer;
