import React, {type ReactElement, useEffect, useRef, useState, useCallback} from 'react';
import styles from '../resources/styles/ComponentSpecific/Canvas.module.css';
import {type VisualizationProps} from './ArgumentTypes/VisualizationProps';

/**
 * React component containing elements of visualization component.
 * It creates a blank canvas. Whenever the image data is updated, the image on the canvas is updated.
 *
 * @param props - The input parameters needed for this component.
 * @returns React component containing elements of visualization component.
 */
export default function VisualizationContents(props: VisualizationProps): ReactElement {
	const [startOfImageX, setStartOfImageX] = useState<GLfloat>(0.0);
	const [startOfImageY, setStartOfImageY] = useState<GLfloat>(0.0);
	const [imageResizeRatio, setImageResizeRatio] = useState<GLfloat>(0.0);

	const canvasRef = useRef<HTMLCanvasElement>(null);

	/**
	 * When the user clicks on the canvas with a mouse, the point is recorded.
	 * The coordinate is recorded relative to the original image size.
	 * It is handled by the function passed by the parent component.
	 *
	 * @param event - The event information caught when the user presses the left-mouse button.
	 */
	function mouseDown(event: React.MouseEvent<HTMLCanvasElement>): void {
		const canvas = canvasRef.current;

		if (canvas && props.imageSource && props.dotsToDraw.length < 4) {
			const {offsetX, offsetY} = event.nativeEvent;
			const {width, height} = canvas.getBoundingClientRect();
			const xCanvas = (offsetX / width) * canvas.width;
			const yCanvas = (offsetY / height) * canvas.height;
			const x = (xCanvas - startOfImageX) / imageResizeRatio;
			const y = (yCanvas - startOfImageY) / imageResizeRatio;
			if (x > 0 && x < props.imWidth && y > 0 && y < props.imHeight) {
				props.onMouseDownFunction(x, y); // Pass the coordinates to the parent component or function}
			}
		}
	}

	/**
	 * If there are dots to draw, they are visualized in red.
	 * The dots are stored with coordinates relative to th original size of the image.
	 * Therefore, they need to be resized.
	 */
	const drawDots = useCallback(() => {
		const canvas = canvasRef.current;
		const context = (canvas) ? canvas.getContext('2d') : undefined;
		if (context && canvas && props.dotsToDraw.length > 0) {
			props.dotsToDraw.forEach(dot => {
				context.beginPath();
				context.arc(
					(dot[0] * imageResizeRatio) + startOfImageX,
					(dot[1] * imageResizeRatio) + startOfImageY,
					4,
					0,
					2 * Math.PI);
				context.fillStyle = 'red';
				context.fill();
				context.closePath();
			});
		}
	}, [props.dotsToDraw, startOfImageX, startOfImageY, imageResizeRatio]);

	/**
	 * If there is image data present, it is drawn on the canvas.
	 * The image is resized according to the settings for the canvas size.
	 * The image is shifted to the center of the canvas.
	 */
	const drawImage = useCallback(() => {
		const canvas = canvasRef.current;
		const context = (canvas) ? canvas.getContext('2d') : undefined;
		if (canvas) {
			canvas.style.width = '70%';
			canvas.style.height = '70%';
			canvas.width = canvas.clientWidth;
			canvas.height = canvas.clientWidth;

			if (context && props.imageSource) {
				context.clearRect(0, 0, canvas.width, canvas.height);
				const img = new Image();
				img.src = `data:image/png;base64,${props.imageSource}`;
				img.onload = () => {
					const hRatio = canvas.width / img.width;
					const vRatio = canvas.height / img.height;
					const ratio = Math.min(hRatio, vRatio);
					const centerShiftX = (canvas.width - (img.width * ratio)) / 2;
					const centerShiftY = (canvas.height - (img.height * ratio)) / 2;

					context.drawImage(
						img,
						0,
						0,
						img.width,
						img.height,
						centerShiftX,
						centerShiftY,
						img.width * ratio,
						img.height * ratio,
					);
					drawDots();

					setStartOfImageX(centerShiftX);
					setStartOfImageY(centerShiftY);
					setImageResizeRatio(ratio);
				};
			} else if (context) {
				context.font = '30px Cambria, Cochin, Georgia, Times, \'Times New Roman\', serif';
				context.fillText(props.label, 10, 30);
			}
		}
	}, [props.imageSource, props.label, drawDots]);

	useEffect(() => {
		drawImage();
		drawDots();
	}, [drawDots, drawImage]);

	return (
		<canvas
			ref={canvasRef}
			className={styles.Canvas}
			onMouseDown={e => {
				mouseDown(e);
			}}
		>
			{props.label}
		</canvas>
	);
}
