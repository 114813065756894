import React, {type ReactElement} from 'react';
import styles from '../resources/styles/ComponentSpecific/Footer.module.css';

/**
 * React component containing elements of footer including copyright.
 *
 * @returns React component containing footer elements.
 */
function FooterContents(): ReactElement {
	return (
		<div className={styles.Footer}>© 2018 Scenwise All Rights Reserved</div>
	);
}

export default FooterContents;
