import React, {type ReactElement} from 'react';
import ReplayContents from '../ReplayContents';
import styles from '../../resources/styles/ComponentSpecific/Replay.module.css';

/**
 * React container containing elements of the replay area container.
 *
 * @returns React container containing elements of the visualization area container.
 */
function ReplayContainer(): ReactElement {
	return (
		<div className={styles.ContainerWrapperPlacing}>
			<ReplayContents/>
		</div>
	);
}

export default ReplayContainer;
