import React, {type ReactElement} from 'react';
import VideoAnalyticsConts from '../VideoAnalyticsContents';
import {type VideoAnalyticsProps} from '../ArgumentTypes/VideoAnalyticsProps';
import styles from '../../resources/styles/ComponentSpecific/VideoAnalytics.module.css';

/**
 * React container containing elements of the video analytics component.
 *
 * @param props - The props of the component.
 * @returns React container containing elements of the video analytics component.
 */
function VideoAnalyticsContainer(props: VideoAnalyticsProps): ReactElement {
	return (
		<div className={styles.ContainerWrapperPlacing}>
			<VideoAnalyticsConts {...props}/>
		</div>
	);
}

export default VideoAnalyticsContainer;
