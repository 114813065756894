import type DeleteReplayPopUpProps from '../ArgumentTypes/DeleteReplayPopUpProps';
import DeleteReplayPopUpContents from '../DeleteReplayPopUpContents';
import React, {type ReactElement} from 'react';

/**
 * Returns a container for the delete replay pop up component.
 *
 * @param props - The input parameters for the container.
 * @returns - React element rendering the delete replay pop up component.
 */
export default function DeleteReplayPopUpContainer(props: DeleteReplayPopUpProps): ReactElement {
	return (
		<div>
			<DeleteReplayPopUpContents {...props}/>
		</div>
	);
}
