import React from 'react';
import stylesCommon from '../resources/styles/ComponentSpecific/MainBody.module.css';
import stylesSources from '../resources/styles/ComponentSpecific/Replay.module.css';
import {type SearchReplayProps} from './ArgumentTypes/SearchReplayProps';
import {type ScheduledRecording} from '../api/model/recording';
import {createState} from 'state-pool';

const recordingsState = createState<ScheduledRecording[]>([]);

/**
 * Function for updating the list of scheduled recordings.
 *
 * @param newRecordings - New list specifying scheduled recordings.
 */
function setRecordingsState(newRecordings: ScheduledRecording[]): void {
	recordingsState.setValue(newRecordings);
}

/**
 * React component containing elements of component for searching for a livestream.
 *
 * @param props - The props of the component, input parameters to the component.
 * @returns React component containing elements of livestream component.
 */
function SearchReplayContents(props: SearchReplayProps): JSX.Element {
	const [recordingsList] = recordingsState.useState();
	const handleRecordingClick = (recording: ScheduledRecording): void => {
		props.setSelectedRecording(recording);
	};

	return (
		<div id='replay_list' className={stylesSources.SearchDiv}>
			<p className={stylesCommon.Text}>Select A Recording</p>
			<div id='search_bar' className={stylesSources.ScrollableList}>
				{(recordingsList && recordingsList.length > 0) ? (
					recordingsList.map(recording => (
						<div
							key={recording.name}
							className={`${stylesSources.ListItem} ${props.selectedRecording?.name === recording.name ? stylesSources.Selected : ''}`}
							onClick={() => {
								handleRecordingClick(recording);
							}}
						>
							{recording.name}
						</div>
					))
				) : (
					<div>
						No Recordings Available
					</div>
				)}
			</div>
		</div>
	);
}

export default SearchReplayContents;
export {recordingsState, setRecordingsState};

