export type Technique = {
	id: string;
	name: string;
	requiresGeoFence: boolean;
	customizableRetentionPeriod: boolean;
	requiresObjectTypes: boolean;
};

export const techniqueList: Technique[] = [
	{
		id: 'OBJECT_DETECTION',
		name: 'Object Detection',
		requiresGeoFence: false,
		customizableRetentionPeriod: true,
		requiresObjectTypes: true,
	},
	{
		id: 'OBJECT_COUNTING',
		name: 'Object Counting',
		requiresGeoFence: false,
		customizableRetentionPeriod: true,
		requiresObjectTypes: true,
	},
	{
		id: 'SPEED_ESTIMATION',
		name: 'Speed Estimation',
		requiresGeoFence: false,
		customizableRetentionPeriod: true,
		requiresObjectTypes: true,
	},
	{
		id: 'CROWD_COUNTING',
		name: 'Crowd Counting',
		requiresGeoFence: false,
		customizableRetentionPeriod: true,
		requiresObjectTypes: false,
	},
];

export const techniqueDictionary: Record<string, Technique> = {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	OBJECT_DETECTION: {
		id: 'OBJECT_DETECTION',
		name: 'Object Detection',
		requiresGeoFence: false,
		customizableRetentionPeriod: true,
		requiresObjectTypes: true,
	},
	// eslint-disable-next-line @typescript-eslint/naming-convention
	OBJECT_COUNTING: {
		id: 'OBJECT_COUNTING',
		name: 'Object Counting',
		requiresGeoFence: false,
		customizableRetentionPeriod: true,
		requiresObjectTypes: true,
	},
	// eslint-disable-next-line @typescript-eslint/naming-convention
	SPEED_ESTIMATION: {
		id: 'SPEED_ESTIMATION',
		name: 'Speed Estimation',
		requiresGeoFence: false,
		customizableRetentionPeriod: true,
		requiresObjectTypes: true,
	},
	// eslint-disable-next-line @typescript-eslint/naming-convention
	CROWD_COUNTING: {
		id: 'CROWD_COUNTING',
		name: 'Crowd Counting',
		requiresGeoFence: false,
		customizableRetentionPeriod: true,
		requiresObjectTypes: false,
	},
};

export const detectableObjects: string[] = ['Car', 'Truck', 'Bus', 'Bicycle', 'Person'];
