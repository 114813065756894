import React, {type ReactElement, useState} from 'react';
import ReactModal from 'react-modal';
import styles from '../resources/styles/ComponentSpecific/MainBody.module.css';
import buttons from '../resources/styles/ElementSpecific/Buttons.module.css';
import containers from '../resources/styles/ElementSpecific/Container.module.css';
import SearchReplay from './containers/SearchReplayContainer';
import AddReplayPopUp from './containers/AddReplayPopUpContainer';
import ViewReplayPopUp from './containers/ViewReplayPopUpContainer';
import DeleteReplayPopUp from './containers/DeleteReplayPopUpContainer';
import DeleteAllReplaysPopUp from './containers/DeleteAllReplaysPopUpContainer';
import {type ScheduledRecording} from '../api/model/recording';
import SelectedReplayIndicator from './containers/SelectedReplayIndicatorContainer';
import {faQuestionCircle} from '@fortawesome/free-regular-svg-icons';
import Tippy from '@tippyjs/react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

/**
 * React component containing elements of replay component.
 *
 * @returns React component containing elements of replay component.
 */
function ReplayContents(): ReactElement {
	const [isOpen, setIsOpen] = useState(false);
	const [isViewReplayOpen, setIsViewReplayOpen] = useState(false);
	const [selectedRecording, setSelectedRecording] = React.useState<ScheduledRecording | undefined>(undefined);
	const [isDeleteReplayOpen, setIsDeleteReplayOpen] = useState(false);
	const [isDeleteAllReplaysOpen, setIsDeleteAllReplaysOpen] = useState(false);

	return (
		<div>
			<h2 className={styles.SectionTitle}>
				Replay/Recordings
				<Tippy content='In this section, you can schedule recordings of the streams that you have added to the system. Once you have scheduled the recording, the system will save the data within the time-span you specify so that you can go back and verify the results'>
					<FontAwesomeIcon icon={faQuestionCircle} style={{marginLeft: '10px'}}/>
				</Tippy>
			</h2>
			<div className={containers.Container} id='Replay'>
				<button
					type='button'
					id='schedule'
					className={buttons.Button}
					onClick={() => {
						setIsOpen(true);
					}}
				>Schedule New Recording
				</button>
				<button
					type='button'
					id='view'
					className={buttons.Button}
					disabled={!selectedRecording}
					onClick={() => {
						setIsViewReplayOpen(true);
					}}
				>View Recording
				</button>
				<button
					type='button'
					id='deleteAll'
					className={buttons.Button}
					onClick={() => {
						setIsDeleteAllReplaysOpen(true);
					}}
				>Delete All Recordings
				</button>
				<button
					type='button'
					id='delete'
					className={buttons.Button}
					disabled={!selectedRecording}
					onClick={() => {
						setIsDeleteReplayOpen(true);
					}}
				>Delete Recording
				</button>
				<SearchReplay
					selectedRecording={selectedRecording}
					setSelectedRecording={setSelectedRecording}
				/>
				<SelectedReplayIndicator selectedRecording={selectedRecording}/>
				<ReactModal
					className={styles.Modal}
					isOpen={isOpen}
					ariaHideApp={false}
					onRequestClose={() => {
						setIsOpen(false);
					}}
				>
					<AddReplayPopUp
						onCloseProp={() => {
							setIsOpen(false);
						}}/>
				</ReactModal>
				<ReactModal
					className={styles.Modal}
					isOpen={isViewReplayOpen}
					ariaHideApp={false}
					onRequestClose={() => {
						setIsViewReplayOpen(false);
					}}
				>
					<ViewReplayPopUp
						selectedRecording={selectedRecording}
						onCloseProp={() => {
							setIsViewReplayOpen(false);
						}}/>
				</ReactModal>
				<ReactModal
					className={styles.Modal}
					isOpen={isDeleteReplayOpen}
					ariaHideApp={false}
					onRequestClose={() => {
						setIsDeleteReplayOpen(false);
					}}
				>
					<DeleteReplayPopUp
						selectedRecording={selectedRecording}
						setSelectedRecording={setSelectedRecording}
						onCloseProp={() => {
							setIsDeleteReplayOpen(false);
						}}/>
				</ReactModal>
				<ReactModal
					className={styles.Modal}
					isOpen={isDeleteAllReplaysOpen}
					onRequestClose={() => {
						setIsDeleteAllReplaysOpen(false);
					}}
				>
					<DeleteAllReplaysPopUp
						setSelectedRecording={setSelectedRecording}
						onCloseProp={() => {
							setIsDeleteAllReplaysOpen(false);
						}}/>
				</ReactModal>
			</div>
		</div>
	);
}

export default ReplayContents;
