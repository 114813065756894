import React, {type ReactElement} from 'react';
import stylesError from '../resources/styles/ErrorAlert.module.css';
import {type ErrorAlertProps} from './ArgumentTypes/ErrorAlertProps';

/**
 * Creates the components for an error alert.
 * @param props - The input parameters needed for the alert.
 * @returns The contents of a n error alert.
 */
export default function ErrorAlertContents(props: ErrorAlertProps): ReactElement {
	const closeOnClick: () => void = () => {
		props.resetError(undefined);
	};

	return (
		<div className={`${stylesError.alert} ${stylesError.alertDismissable}`}>
			<button
				type='button'
				data-dismiss='alert'
				aria-label='close'
				className={stylesError.close}
				onClick={closeOnClick}
			>
				X
			</button>
			<p>{props.message}</p>
		</div>
	);
}
