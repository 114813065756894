import {type SetGeoFenceProps} from '../ArgumentTypes/CreateGeoFenceProps';
import GeoFencePopUpContents from '../GeoFencePopUpContents';
import React, {type ReactElement} from 'react';

/**
 * Returns a container for the geo-fence component.
 *
 * @param props - The input parameters for the container.
 * @returns - React element rendering the geo-fence component.
 */
export default function GeoFencePopUpContainer(props: SetGeoFenceProps): ReactElement {
	return (
		<div>
			<GeoFencePopUpContents {...props}/>
		</div>
	);
}
