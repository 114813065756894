import React, {type ReactElement} from 'react';
import styles from '../resources/styles/ComponentSpecific/PopUp.module.css';
import buttons from '../resources/styles/ElementSpecific/Buttons.module.css';
import type Props from './ArgumentTypes/DeleteAllReplaysProps';
import type ApiGatewaySocket from '../api/server';
import {useWebSocket} from '../api/AGWContext';
import VisualizationService from '../api/VisualizationService';
import VideoService from '../api/VideoService';

/**
 * Component which contains component of the delete all replays popup.
 *
 * @param props - The props of the component.
 * @returns The jsx of the component.
 */
function DeleteAllReplaysPopUpContents(props: Props): ReactElement {
	const ws: ApiGatewaySocket | undefined = useWebSocket();

	/**
	 * Confirmation of delete all replays.
	 *
	 * @param event - The submit event.
	 * @param onClose - Function which determines closing behavior of pop-up.
	 */
	const handleDeleteAllReplays = (
		event: React.MouseEvent<HTMLButtonElement>,
		onClose: () => void,
	): void => {
		event.preventDefault();
		if (ws) {
			const visualizationService = new VisualizationService(ws);
			visualizationService.deleteAllRecordings(
			);
			const videoService = new VideoService(ws);
			videoService.deleteAllRecordings(
			);
			props.setSelectedRecording(undefined);
		}

		onClose();
	};

	const confirmationText = (
		<span>
			Are you certain you want to delete all recordings? This is a <strong>destructive operation</strong>.
		</span>
	);

	return (
		<form className={styles.Window}>
			<h1 className={styles.Title}>Delete All Recordings</h1>
			<p className={styles.Text}>{confirmationText}</p>
			<div className={styles.ButtonsDiv}>
				<button
					type='button'
					id='Close'
					className={buttons.Button}
					onClick={props.onCloseProp}
				>
					Cancel
				</button>
				<button
					type='submit'
					className={buttons.Button}
					onClick={e => {
						handleDeleteAllReplays(e, props.onCloseProp);
					}}
				>
					Delete All Recordings
				</button>
			</div>
		</form>
	);
}

export default DeleteAllReplaysPopUpContents;
