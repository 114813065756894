import React, {type ReactElement} from 'react';
import {type DataResultsProps} from './ArgumentTypes/DataResultsProps';
import {type SpeedData, type Metadata} from '../Types/MetadataTypes';
import styles from '../resources/styles/ComponentSpecific/DataResults.module.css';

/**
 * This function returns the results stylized and ordered depending on the type of result.
 * @param props - The input parameters needed for this component.
 * @returns Element containg the stylized results.
 */
export default function DataResults(props: DataResultsProps): ReactElement {
	if ('counts' in props.metadata) {
		const counts = props.metadata.counts as Record<string, number>;
		if (props.metadata.counts) {
			const keys = Object.keys(counts);
			return (
				<div>
					<p className={styles.HeaderResults}>Vehicle: Count</p>
					{keys.map(item => (
						<p key={item}>{item}: {counts[item]}</p>
					))}
				</div>
			);
		}

		return <p>Nothing detected.</p>;
	}

	if ('speed_data' in props.metadata) {
		const speed = props.metadata.speed_data as SpeedData[];
		const averageSpeed = props.metadata.avg_speed as number;
		const density = props.metadata.density as number;
		const vehicleAmount = props.metadata.vehicle_amount_speed as number;
		return (
			<div>
				<p>Average speed: {averageSpeed}km/h</p>
				{density !== null && <p>Density: {density} vehicles/km</p>}
				{density === null && <p>Density: Needs a geo-fence!</p>}
				<p>Number of vehicles: {vehicleAmount}</p>
				<p className={styles.HeaderResults}>ID: Speed</p>
				{speed.map(item => (
					<p key={item.id}>{item.id}: {item.speed}km/h</p>
				))}
			</div>
		);
	}

	if ('people' in props.metadata) {
		return <p>There are {props.metadata.people} people</p>;
	}

	if (props.metadata as Metadata) {
		return (<p> The results are visualized: </p>);
	}

	return (<p> The results are visualized: </p>);
}
