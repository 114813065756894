import React, {type ReactElement} from 'react';
import logo from '../resources/images/scenwise-logo.png';
import styles from '../resources/styles/ComponentSpecific/Header.module.css';

/**
 * React component containing elements of header including Scenwise logo and title.
 *
 * @returns React component containing header elements.
 */
function HeaderContents(): ReactElement {
	return (
		<div className={styles.header}>
			<img id='logo' className={styles.img} alt='' src={logo}/>
			<h1 className={styles.title}> Video Analytics Service</h1>
		</div>
	);
}

export default HeaderContents;
