import {type SelectViewPopUpProps} from '../ArgumentTypes/SelectViewPopUpProps';
import SelectCameraViewPopUpContents from '../SelectCameraViewPopUpContents';
import React, {type ReactElement} from 'react';

/**
 * Returns a container for the select view component.
 *
 * @param props - The input parameters for the container.
 * @returns - React element rendering the select view component.
 */
export default function SelectCameraViewPopUpContainer(props: SelectViewPopUpProps): ReactElement {
	return (
		<div>
			<SelectCameraViewPopUpContents {...props}/>
		</div>
	);
}
