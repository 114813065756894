import type ApiGatewaySocket from './server';
import {type Message} from './model/messages';

/**
 * Handles message from api_gateway. Receives generated client_id from api_gateway and sets the socket's
 * clientId to this value.
 *
 * @param message - The message received.
 * @param socket - The websocket connection.
 */
export function handleAgwMessage(
	message: Message,
	socket: ApiGatewaySocket,
): void {
	const {payload} = message;

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const {body} = payload;

	switch (payload.payload_type) {
		case 'RESPOND_CLIENT_ID':
			handleClientIdMessage(body, socket);
			break;
		case 'HEARTBEAT':
			// Do nothing
			break;
		default:
			throw Error('Invalid payload type');
	}
}

/**
 * Handler for message which sends a generated client id.
 *
 * @param body - The body of the message.
 * @param socket - The socket connecting to the api_gateway.
 */
export function handleClientIdMessage(
	body: any,
	socket: ApiGatewaySocket,
): void {
	if (typeof body !== 'string') {
		throw Error('Expected string body for client_id message');
	}

	socket.setClientId(body);
}

export default handleAgwMessage;
