import React, {type ReactElement} from 'react';
import stylesCommon from '../resources/styles/ComponentSpecific/MainBody.module.css';
import stylesSources from '../resources/styles/ComponentSpecific/VideoSources.module.css';
import {createState} from 'state-pool';
import {type SetSourcesProps} from './ArgumentTypes/SetSourcesProps';
import {streamTechniqueDictionary} from './MainBodyContents';
import {techniqueDictionary} from '../Types/Technique';
import {type StreamTechniqueDictionaryType} from '../Types/StreamTechniqueDictionary';

const livestreamNamesState = createState<string[] | undefined>(undefined);

/**
 * Sets the names of the livestreams found.
 *
 * @param names - The names of the streams.
 */
function setLivestreamNamesState(names: string[]): void {
	livestreamNamesState.setValue(names);
}

/**
 * React component containing elements of component for searching for a livestream.
 *
 * @param props - The props of the component, input parameters to the component.
 * @returns React component containing elements of livestream component.
 */
function SearchLivestreamComponent(props: SetSourcesProps): ReactElement {
	const [streamsBeingProcessed] = livestreamNamesState.useState();

	const allStreams: string[] = streamsBeingProcessed
		? [...streamsBeingProcessed, ...props.streamsRequiringViewSelection.map(([, name]) => name)]
		: props.streamsRequiringViewSelection.map(([, name]) => name);

	const handleLivestreamChange = (
		event: React.ChangeEvent<HTMLSelectElement>,
	): void => {
		const selectedLivestream: string = event.target.value;

		if (selectedLivestream === '') {
			return;
		}

		props.setSourceName(selectedLivestream);

		const streamTechniqueDictionaryValue = streamTechniqueDictionary.getValue() as StreamTechniqueDictionaryType;

		if (typeof streamTechniqueDictionaryValue === 'object' && streamTechniqueDictionaryValue !== null) {
			const techniques: string[] | undefined = streamTechniqueDictionaryValue[selectedLivestream];
			const techniqueObjects = techniques.map(technique => techniqueDictionary[String(technique)]);
			props.setAnalysisTechniques(techniqueObjects);
		} else {
			throw new Error('Techniques were not retrieved properly.');
		}
	};

	return (
		<div id='search_livestream' className={stylesSources.SearchDiv}>
			<p className={stylesCommon.Text}>Select which video stream you would like to view</p>
			<div id='search_bar'>
				<select
					value={props.sourceName || 'Select a livestream'}
					className={stylesSources.DropDown}
					onChange={handleLivestreamChange}
				>
					{allStreams && allStreams.length > 0 ? (
						allStreams.map(name => (
							<option key={name} value={name}>
								{name}
							</option>
						))
					) : (
						<option disabled>Please add sources first</option>
					)}
				</select>
			</div>
		</div>
	);
}

export default SearchLivestreamComponent;
export {livestreamNamesState, setLivestreamNamesState};
