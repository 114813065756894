import React, {type ReactElement} from 'react';
import SourceIndicatorContents from '../SourceIndicatorContents';
import {type SourceIndicatorProp} from '../ArgumentTypes/SourceIndicatorProp';
import styles from '../../resources/styles/ComponentSpecific/VideoSources.module.css';

/**
 * React container containing elements of the component indicating the selected video source.
 *
 * @param props - The props of the component.
 * @returns React container containing elements of the video source indicator component.
 */
function SourceIndicatorContainer(props: SourceIndicatorProp): ReactElement {
	return (
		<div className={styles.SourceIndContainer}>
			<SourceIndicatorContents sourceName={props.sourceName}/>
		</div>
	);
}

export default SourceIndicatorContainer;
