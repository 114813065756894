import React, {type ReactElement} from 'react';
import {type ErrorScreenProps} from './ArgumentTypes/ErrorScreenProps';
import stylesError from '../resources/styles/ErrorScreen.module.css';

/**
 * The contents of the scren that appears in case of an error the user cannot handle.
 * @param props - The input parameters needed for the error screen.
 * @returns The contents of the Error Screen.
 */
export default function ErrorScreen(props: ErrorScreenProps): ReactElement {
	return (
		<div className={stylesError.ErrorDiv}>
			<h1 className={stylesError.h1}>Error: {props.errorName}</h1>
			{props.tip && <p className={stylesError.Tips}>{props.tip}</p>}
		</div>
	);
}
