import React, {type ReactElement} from 'react';
import Header from './components/containers/HeaderContainer';
import Footer from './components/containers/FooterContainer';
import MainBody from './components/containers/MainBodyContainer';
import styles from './resources/styles/ComponentSpecific/App.module.css';
import {createState} from 'state-pool';
import ErrorScreen from './components/ErrorScreen';

const error = createState<[string, string] | undefined>(undefined);

/**
 * Sets the error name and tip that will be displayed.
 * @param errorName - The nam eof the error.
 * @param errorTip - The tip provide with the error.
 */
export function setError(errorName: string, errorTip: string): void {
	error.setValue([errorName, errorTip]);
}

/**
 * Render the main page component.
 *
 * @returns React component containing all elements of the main page.
 */
function App(): ReactElement {
	const [errorThrown] = error.useState();

	if (errorThrown) {
		return (
			<div className={styles.Page}>
				<Header/>
				<ErrorScreen
					errorName={errorThrown[0]}
					tip={errorThrown[1]}/>;
				<Footer/>
			</div>
		);
	}

	return (
		<div className={styles.Page}>
			<Header/>
			<MainBody/>
			<Footer/>
		</div>
	);
}

export default App;
