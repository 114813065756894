/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {type Message} from './model/messages';
import {setLivestreamNamesState} from '../components/SearchLivestreamContents';
import {setWidthGeo, setHeightGeo, addGeoFenceImage} from '../components/GeoFencePopUpContents';
import {type SendVisualBody} from '../Types/VisualizationBody';
import {setErrorAlert, updateTechniquesDictionary} from '../components/MainBodyContents';
import {updateViews} from '../components/SelectCameraViewPopUpContents';
import {setRecordingsState} from '../components/SearchReplayContents';
import {type ScheduledRecording} from './model/recording';

/**
 * Handle a message from the video service.
 *
 * @param message - The message received.
 */
export default function handleVideoServiceMessage(message: Message): void {
	const {body} = message.payload;
	switch (message.payload.payload_type) {
		case 'SEND_LIVESTREAM_NAMES_AND_TECHNIQUES': {
			setLivestreamNamesState(Object.keys(body));
			updateTechniquesDictionary(body);
			break;
		}

		case 'SEND_SINGLE_FRAME': {
			if (body satisfies SendVisualBody) {
				// @typescript-eslint/no-unsafe-assignment,@typescript-eslint/naming-convention
				// eslint-disable-next-line @typescript-eslint/naming-convention
				const {width, height, original_frame, meta_data: {stream_name}} = body; // Replace with actual width of image
				addGeoFenceImage(stream_name, original_frame);
				setHeightGeo(height);
				setWidthGeo(width);
			} else {
				throw Error('Expected message body to have width and height attributes');
			}

			break;
		}

		case 'SEND_RECORDINGS_DATA': {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			const recordings: ScheduledRecording[] = body.map((recording: any) => ({
				name: recording.name,
				livestreamName: recording.livestream_name,
				technique: recording.technique,
				startDateTime: recording.start_datetime,
				endDateTime: recording.end_datetime,
			}));
			setRecordingsState(recordings);
			break;
		}

		case 'SEND_FRAME_ERROR': {
			setErrorAlert(String(body));
			break;
		}

		case 'SEND_FRAME_WARNING': {
			setErrorAlert(String(body));
			break;
		}

		case 'SEND_VIEW': {
			if (body) {
				// @typescript-eslint/no-unsafe-assignment,@typescript-eslint/naming-convention
				// eslint-disable-next-line @typescript-eslint/naming-convention
				const {original_frame, meta_data: {date_time, stream_name, percentage_sent}} = body;
				updateViews(stream_name, original_frame, date_time, percentage_sent);
			} else {
				throw Error('Expected message body to contain image');
			}

			break;
		}

		default: {
			throw Error('Unexpected payload type');
		}
	}
}
