import React, {type ReactElement} from 'react';
import ObjectsDetectChecklistContents from '../ObjectsDetectChecklistContents';
import type Props from '../ArgumentTypes/ObjectsDetectProps';
import styles from '../../resources/styles/ComponentSpecific/Checklist.module.css';

/**
 * React container containing elements of a scrollable checklist for the objects to detect.
 *
 * @param props - The props of the component.
 * @returns React container containing elements of a checklist for objects to detect.
 */
function ObjectsDetectChecklistContainer(props: Props): ReactElement {
	return (
		<div className={styles.Container}>
			<ObjectsDetectChecklistContents {...props}/>
		</div>
	);
}

export default ObjectsDetectChecklistContainer;
