import React, {type ReactElement} from 'react';
import styles from '../resources/styles/ComponentSpecific/PopUp.module.css';
import buttons from '../resources/styles/ElementSpecific/Buttons.module.css';
import type Props from './ArgumentTypes/DeleteReplayPopUpProps';
import type ApiGatewaySocket from '../api/server';
import {useWebSocket} from '../api/AGWContext';
import VideoService from '../api/VideoService';
import VisualizationService from '../api/VisualizationService';

/**
 * Component which contains component of the delete replay popup.
 *
 * @param props - The props of the component.
 * @returns The jsx of the component.
 */
function DeleteReplayPopUpContents(props: Props): ReactElement {
	const ws: ApiGatewaySocket | undefined = useWebSocket();

	/**
	 * Confirmation of delete replay.
	 *
	 * @param event - The submit event.
	 * @param onClose - Function which determines closing behavior of pop-up.
	 */
	const handleDelete = (
		event: React.MouseEvent<HTMLButtonElement>,
		onClose: () => void,
	): void => {
		event.preventDefault();
		if (ws && props.selectedRecording) {
			const videoService = new VideoService(ws);
			videoService.deleteRecording(
				props.selectedRecording.name,
			);
			const visualizationService = new VisualizationService(ws);
			visualizationService.deleteRecording(
				props.selectedRecording.name,
			);
			props.setSelectedRecording(undefined);
		}

		onClose();
	};

	const confirmationText = props.selectedRecording && (
		<span>
			Are you certain you wish to delete the recording <strong>{props.selectedRecording.name}</strong>?
			It is recording the following technique <strong>{props.selectedRecording.technique}</strong> on the stream <strong>{props.selectedRecording.livestreamName}</strong>.
			The recording is scheduled from <strong>{props.selectedRecording.startDateTime}</strong> until <strong>{props.selectedRecording.endDateTime}</strong>
		</span>
	);

	return (
		<form className={styles.Window}>
			<h1 className={styles.Title}>Delete Recording</h1>
			<p className={styles.Text}>{confirmationText}</p>
			<div className={styles.ButtonsDiv}>
				<button
					type='button'
					id='Close'
					className={buttons.Button}
					onClick={props.onCloseProp}
				>
					Cancel
				</button>
				<button
					type='submit'
					className={buttons.Button}
					onClick={e => {
						handleDelete(e, props.onCloseProp);
					}}
				>
					Delete Recording
				</button>
			</div>
		</form>
	);
}

export default DeleteReplayPopUpContents;
