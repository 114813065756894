import React, {type ReactElement} from 'react';
import MainBodyConts from '../MainBodyContents';

/**
 * React container containing elements of the main body.
 *
 * @returns React container containing elements of the main body.
 */
function MainBodyContainer(): ReactElement {
	return (
		<div>
			<MainBodyConts/>
		</div>
	);
}

export default MainBodyContainer;
