import React, {type ReactElement} from 'react';
import AnalysisResultsConts from '../AnalysisResultsContents';
import styles from '../../resources/styles/ComponentSpecific/AnalysisResults.module.css';
import {type AnalysisResultsProps} from '../ArgumentTypes/AnalysisResultsProps';

/**
 * React container containing elements of video analytics component.
 *
 * @param props - The inputs needed by the Results component.
 * @returns React container containing elements of video analytics component.
 */
function AnalyticsResultsContainer(props: AnalysisResultsProps): ReactElement {
	return (
		<div className={styles.WrapperContainer}>
			<AnalysisResultsConts {...props}/>
		</div>
	);
}

export default AnalyticsResultsContainer;
