import React, {type ReactElement} from 'react';
import SearchLivestreamContents from '../SearchLivestreamContents';
import {type SetSourcesProps} from '../ArgumentTypes/SetSourcesProps';
import styles from '../../resources/styles/ComponentSpecific/VideoSources.module.css';

/**
 * React container containing elements of the livestream search bar component.
 *
 * @param props - The props of the component.
 * @returns React container containing elements of the livestream search bar component.
 */
function SearchLivestreamContainer(props: SetSourcesProps): ReactElement {
	return (
		<div className={styles.Container}>
			<SearchLivestreamContents {...props}/>
		</div>
	);
}

export default SearchLivestreamContainer;
