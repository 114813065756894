import React, {type ReactElement} from 'react';
import SearchReplayContents from '../SearchReplayContents';
import {type SearchReplayProps} from '../ArgumentTypes/SearchReplayProps';
import styles from '../../resources/styles/ComponentSpecific/VideoSources.module.css';

/**
 * React container containing elements of the replay list component.
 *
 * @param props - The props of the component.
 * @returns React container containing elements of the replay search bar component.
 */
function SearchReplayContainer(props: SearchReplayProps): ReactElement {
	return (
		<div className={styles.Container}>
			<SearchReplayContents {...props}/>
		</div>
	);
}

export default SearchReplayContainer;
