import React, {type ReactElement, useState} from 'react';
import type {SelectViewPopUpProps} from './ArgumentTypes/SelectViewPopUpProps';
import styles from '../resources/styles/ComponentSpecific/PopUp.module.css';
import buttons from '../resources/styles/ElementSpecific/Buttons.module.css';
import stylesSources from '../resources/styles/ComponentSpecific/Replay.module.css';
import {createState} from 'state-pool';
import type ApiGatewaySocket from '../api/server';
import {useWebSocket} from '../api/AGWContext';
import VideoService from '../api/VideoService';
import {type Technique, techniqueList} from '../Types/Technique';

const views = createState<Record<string, Array<[string, string, string]>>>({});

/**
 * Function for updating the views.
 *
 * @param streamName - Name of the stream to add view for.
 * @param newView - New view to add.
 * @param timestamp - Timestamp of the new view.
 * @param percentageReceived - Percentage of views that have arrived.
 */
export function updateViews(streamName: string, newView: string, timestamp: string, percentageReceived: string): void {
	views.setValue(prevState => ({
		...prevState,
		[streamName]: prevState[streamName] ? [...prevState[streamName], [newView, timestamp, percentageReceived]] : [[newView, timestamp, percentageReceived]],
	}));
}

/**
 * @param props - The input parameters passed to the component.
 *
 * @returns A React element containing the elements of the pop-up window used for selecting a view for a moving camera.
 */
export default function SelectCameraViewPopUpContents(props: SelectViewPopUpProps): ReactElement {
	const ws: ApiGatewaySocket | undefined = useWebSocket();
	const [viewsDataMap] = views.useState();
	const viewsData = viewsDataMap[props.sourceName];
	const percentageComplete = viewsData?.[viewsData.length - 1]?.[2]
		? viewsData?.[viewsData.length - 1]?.[2]
		: 0;
	const [currentFrameIndex, setCurrentFrameIndex] = useState(0);
	const [stepSize, setStepSize] = useState(1);

	/**
	 * Selects the camera view, sending it to the back-end.
	 *
	 */
	const selectView = (): void => {
		if (ws) {
			const videoService = new VideoService(ws);
			const streamRequiringViewSelection = props.getAndRemoveStreamRequiringViewByName(props.sourceName);
			if (streamRequiringViewSelection) {
				videoService.addSource(
					streamRequiringViewSelection[0],
					streamRequiringViewSelection[1],
					false,
					false,
					streamRequiringViewSelection[2],
					undefined,
					currentFrameIndex + 1,
					streamRequiringViewSelection[3]);
				const techniqueObjects = streamRequiringViewSelection[2].map(id => techniqueList.find(technique => technique.id === id)).filter(Boolean);
				if (techniqueObjects) {
					props.setAnalysisTechniques(techniqueObjects as Technique[]);
				}
			} else {
				throw new Error('Error selecting view for moving camera stream.');
			}
		}
	};

	// Handler for jumping to the start of the replay.
	const handleJumpToStart = (): void => {
		setCurrentFrameIndex(0);
	};

	// Handler for jumping to the end of the replay.
	const handleJumpToEnd = (): void => {
		setCurrentFrameIndex((viewsData?.length ?? 1) - 1);
	};

	// Handler for changing the step size in replay.
	const handleStepSizeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setStepSize(Number(event.target.value));
	};

	// Handler for moving to the next frame based on step size.
	const handleNextFrame = (): void => {
		setCurrentFrameIndex(prev => Math.min(prev + stepSize, (viewsData?.length ?? 1) - 1));
	};

	// Handler for moving to the previous frame based on step size.
	const handlePreviousFrame = (): void => {
		setCurrentFrameIndex(prev => Math.max(prev - stepSize, 0));
	};

	return (
		<div className={styles.Window} data-testid='SelectViewPopUpContents'>
			<h1 style={{textAlign: 'center'}}>Select a Camera View to Process</h1>

			<div className={styles.LabelTextDiv}>
				<label>So far <strong>{percentageComplete}%</strong> of your requested camera view options have arrived.</label>
			</div>

			<img
				id='ViewImage'
				className={stylesSources.Image}
				src={`data:image/png;base64,${viewsData?.[currentFrameIndex]?.[0] || ''}`}
				alt='frame'
			/>

			<input
				type='range'
				min='0'
				max={(viewsData?.length ?? 1) - 1}
				value={currentFrameIndex}
				className={styles.InputRange}
				onChange={event => {
					setCurrentFrameIndex(Number(event.target.value));
				}}
			/>

			<div className={styles.ButtonsDiv}>
				<button
					type='button'
					className={buttons.Button}
					disabled={currentFrameIndex === (viewsData?.length ?? 1) - 1}
					onClick={handleNextFrame}
				>
					Next Frame
				</button>
				<button
					type='button'
					className={buttons.Button}
					disabled={currentFrameIndex === 0}
					onClick={handlePreviousFrame}
				>
					Previous Frame
				</button>
			</div>

			<div className={styles.LabelTextDiv}>
				<label>Step Size (Frames):</label>
				<input
					type='number'
					min='1'
					value={stepSize}
					className={`${styles.Input} ${styles.ViewInput}`}
					onChange={handleStepSizeChange}
				/>
			</div>

			<div className={styles.ButtonsDiv}>
				<button
					type='button'
					className={buttons.Button}
					onClick={handleJumpToEnd}
				>
					Jump to End
				</button>
				<button
					type='button'
					className={buttons.Button}
					onClick={handleJumpToStart}
				>
					Jump to Start
				</button>
			</div>
			<br/>
			<div className={styles.ButtonsDiv}>
				<button
					type='button'
					className={buttons.Button}
					onClick={() => {
						selectView();
						props.onCloseProp();
					}}
				>
					Select View
				</button>
				<button
					className={buttons.Button}
					id='Close'
					type='button'
					onClick={props.onCloseProp}
				>
					Close
				</button>
			</div>
		</div>
	);
}
