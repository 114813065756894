import React, {type ReactElement} from 'react';
import FooterContents from '../FooterContents';
import styles from '../../resources/styles/ComponentSpecific/Containers.module.css';

/**
 * React container containing elements of the footer containing the copyright.
 *
 * @returns React container containing elements of the footer.
 */
function FooterContainer(): ReactElement {
	return (
		<div className={styles.Footer}>
			<FooterContents/>
		</div>
	);
}

export default FooterContainer;
