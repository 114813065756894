import React, {type ReactElement} from 'react';
import styles from '../resources/styles/ComponentSpecific/Techniquesgrid.module.css';
import {type Technique} from '../Types/Technique';
import {type TechniquesGridProps} from './ArgumentTypes/TechniquesGridProps';
import VideoService from '../api/VideoService';
import type ApiGatewaySocket from '../api/server';
import {useWebSocket} from '../api/AGWContext';

/**
 * React component containing elements of the grid of techniques.
 *
 * @param props - The input parameters given to this component from parent components.
 * @returns React component containing elements of the grid of techniques.
 */
function TechniquesGrid(props: TechniquesGridProps): ReactElement {
	const ws: ApiGatewaySocket | undefined = useWebSocket();

	/**
	 * Changes the view of the user.
	 *
	 * @param event - The event that triggers changing of the view.
	 * @param item - The item that represents the technique that will be viewed.
	 */
	const changeView = (
		event: React.MouseEvent<HTMLButtonElement>,
		item: Technique,
	): void => {
		props.setSelected(item);

		if (ws) {
			const videoService = new VideoService(ws);
			videoService.selectVisualization(
				props.selectedStream,
				item.id,
			);
		}
	};

	return (
		<div className={styles.ContainingGrid}>
			{props.analysisTechniques.map(item => (
				<button
					key={item.name}
					disabled={item.requiresGeoFence}
					type='button'
					className={
						props.selected && props.selected === item
							? styles.Selected
							: styles.NotSelected
					}
					onClick={e => {
						changeView(e, item);
					}}
				>
					{item.name}
				</button>
			))}
		</div>
	);
}

export default TechniquesGrid;
