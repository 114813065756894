import React, {type ReactElement} from 'react';
import AddReplayPopUpContents from '../AddReplayPopUpContents';
import type Props from '../ArgumentTypes/AddReplayPopUpProps';

/**
 * React container containing elements of add replay pop up component.
 *
 * @param props - The props of the component.
 * @returns React container containing elements of replay pop up component.
 */
function AddReplayPopUpContainer(props: Props): ReactElement {
	return (
		<div>
			<AddReplayPopUpContents {...props}/>
		</div>
	);
}

export default AddReplayPopUpContainer;
