import React, {type ReactElement} from 'react';
import {type DayCurveProps} from '../ArgumentTypes/DayCurveProps';
import DayCurve from '../DayCurve';
import styles from '../../resources/styles/DayCurve.module.css';

/**
 * Returns a container with the DayCurve contents.
 * @param props - The props needed for the DayCurve.
 * @returns - A container with the DayCurve elements.
 */
export default function DayCurveContainer(props: DayCurveProps): ReactElement {
	return (
		<div className={styles.DayCurveContainer}>
			<DayCurve {...props}/>
		</div>
	);
}
